import React, { useState } from "react";
import signupImage from "../assets/Images/signupImage.png";
import logo from "../assets/Images/tandemLogo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [companyRole, setCompanyRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert("Make sure you enter the same password when confirming");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        "https://tandemapi.ecocube.io/create-user",
        {
          firstName,
          lastName,
          email,
          password,
          phoneNumber,
          companyRole,
        }
      );

      console.log(response.data); // Log the response from the server
      alert("User Created successfully!");
      setLoading(false);
      navigate("/overview");
    } catch (error) {
      setLoading(false);
      if (error) {
        // Clear input fields
        setFirstName("");
        setLastName("")
        setEmail("")
        setPassword("");
        setConfirmPassword("")
        setCompanyRole("")
        setPhone("")
        // Display error message overlay
        setError("Error creating user! Try again.");
        setTimeout(() => {
          setError(null);
        }, 5000);
      } else {
        alert(error.message);
      }
    }
  };

  const closeError = () => {
    setError(null);
  };

  return (
    <div className="grid grid-cols-2 min-h-screen max-h-screen">
      {loading && (
        <div className="absolute inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      )}
      {error && ( // Conditionally render error overlay
        <div className="absolute inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-md shadow-lg">
            <button
              onClick={closeError}
              className="text-gray-400 hover:text-gray-600"
            >
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <p className="text-red-500">{error}</p>
          </div>
        </div>
      )}
      <form onSubmit={handleSignUp} className="col-span-1 p-6 shadow-2xl">
        <div className="w-full flex flex-col items-center justify-center">
          <img
            src={logo}
            alt="Tandem Logo"
            className="flex w-48 justify-center"
          />
          <h2 className="mt-2 leading-9 tracking-tight text-gray-900">
            Sign up for an account
          </h2>
        </div>

        <div className="space-y-6">
          <div className="">
            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium leading-3 text-gray-900"
                >
                  First name:
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Enter your name"
                    autoComplete="given-name"
                    className="block p-2 w-full bg-slate-100 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-3"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-3 text-gray-900"
                >
                  Last name:
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    placeholder="Enter your name"
                    autoComplete="family-name"
                    className="block p-2 bg-slate-100 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-3"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-3 text-gray-900"
                >
                  Email Id:
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="noel@tande.com"
                    autoComplete="given-name"
                    className="block bg-slate-100 p-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-3"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-3 text-gray-900"
                >
                  Mobile NO.:
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="+260 9XXXXXXXX"
                    autoComplete="09XXXXXXXX"
                    className="block bg-slate-100 p-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-3"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-3 text-gray-900"
                >
                  Password:
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="xxxxxxxxxx"
                    autoComplete="given-name"
                    className="block bg-slate-100 p-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-3"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="confirm password"
                  className="block text-sm font-medium leading-3 text-gray-900"
                >
                  Confirm Password:
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    name="confirm password"
                    id="confirm password"
                    placeholder="xxxxxxxxxx"
                    autoComplete="family-name"
                    className="block bg-slate-100 p-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-3"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="company role"
                  className="block text-sm font-medium leading-3 text-gray-900"
                >
                  Company Role:
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="company role"
                    id="company role"
                    placeholder="Enter company role..."
                    autoComplete="given-name"
                    className="block bg-slate-100 p-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-3"
                    value={companyRole}
                    onChange={(e) => {
                      setCompanyRole(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="sm:col-span-3"></div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <button
                  type="submit"
                  className="flex w-3/4 justify-center rounded-md bg-blue-900 px-3 py-2 leading-6 text-white shadow-lg hover:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Sign Up
                </button>
              </div>

              <div className="sm:col-span-3"></div>
            </div>
          </div>
        </div>
      </form>
      <div className="col-span-1 bg-slate-200 flex items-center justify-center p-2">
        <img src={signupImage} alt="side" />
      </div>
    </div>
  );
}
