import React from "react";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  return (
    <div>
      <p className="m-8">
        Forgot password Page <br />
      </p>
      <Link
        to="/"
        className="bg-blue-900 p-2 m-8 rounded-md shadow-xl text-white"
      >
        Go back to Login
      </Link>
    </div>
  );
};

export default ForgotPassword;
