import React from 'react'
import SideNav from '../Components/SideNav'

const Settings = () => {
  return (
    <SideNav>
        <div className='text-3xl text-bold mb-4'>
            Settings
        </div>
    </SideNav> 
  )
}

export default Settings