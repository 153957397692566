import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { format, parseISO, subDays, subMonths } from "date-fns";
import { getDocs, collection, getFirestore } from "firebase/firestore";
import { db } from "../Firebase/firebaseConfig";
import { Dots } from "react-activity";



const AreaGraph = ({ data, statsData }) => {
  const [selectedOption, setSelectedOption] = useState("lastMonth");
  const [selectedTank, setSelectedTank] = useState();
  const [fullData, setFullData] = useState([]);
  const [tankName, setTankName] = useState([]);
  const [tanks, setTanks] = useState([]);

  useEffect(() => {
    getName();
    setSelectedTank(statsData[0]?.deviceID)
    console.log(statsData);
    const mappedArray = statsData.map(item => {
      const name = tankName[item.deviceID];
      return { ...item, name };
    });

    setTanks(mappedArray)

    console.log(mappedArray);
  }, [statsData])

  const getName = async () => {
    const deviceSnapshot = await getDocs(collection(db, "devices"));
    const deviceData = deviceSnapshot.docs.reduce((acc, doc) => {
      acc[doc.id] = doc.data().name;
      return acc;
    }, {});
    setTankName(deviceData)
    console.log(deviceData);
  }


  const getData = async () => {
    const statsSnapshot = await getDocs(collection(db, "stats"));
    const batStatusSnapshot = await getDocs(collection(db, "batStatus"));

    const statsData = statsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    const batStatusData = batStatusSnapshot.docs.reduce((acc, doc) => {
      acc[doc.id] = doc.data().bat;
      return acc;
    }, {});
    // console.log(batStatusData);

    const combinedData = statsData.map(entry => {
      const { deviceID } = entry;
      const batteryStatus = batStatusData[deviceID] || 0; // Default value if battery status not found
      return { ...entry, batteryStatus };
    });


    const id = statsData[0].deviceID;
    // const arr = combinedData.filter(item => item.deviceID === id);
    const extractData = (filteredData) => {
      return filteredData.map(item => {
        return {
          deviceID: item.deviceID,
          date: new Date(item.createdAt.seconds * 1000), // Convert seconds to milliseconds
          depth: item.depth
        };
      });
    };

    // Example usage:
    const finalData = extractData(combinedData);
    // console.log(extractedArray);
    console.log(finalData);
    setFullData(finalData)
    return finalData
  }








  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleChangeTank = (event) => {
    console.log(event.target.value);
    setSelectedTank(event.target.value);
    getGraphData(event.target.value)
  };

  const generateDataForSelectedOption = () => {
    let startDate;
    if (selectedOption === "lastWeek") {
      startDate = subDays(new Date(), 8); // Start from 6 days ago to include the current day
    } else if (selectedOption === "lastMonth") {
      startDate = subMonths(new Date(), 1);
    } else {
      // selectedOption === "lastYear"
      startDate = subMonths(new Date(), 12); // Start from 11 months ago to include the current month
    }
    console.log(data.filter((item) => parseISO(item.date) >= startDate));
    return data.filter((item) => parseISO(item.date) >= startDate);
  };




  const filterData = (data, selectedOption) => {
    let startDate;
    if (selectedOption === "lastWeek") {
      startDate = subDays(new Date(), 8); // Start from 6 days ago to include the current day
    } else if (selectedOption === "lastMonth") {
      startDate = subMonths(new Date(), 1);
    } else {
      // selectedOption === "lastYear"
      startDate = subMonths(new Date(), 12); // Start from 11 months ago to include the current month
    }

    console.log(selectedTank);
    const info = data.filter((item) => parseISO(item.date) >= startDate)
    return info.filter(item => item.deviceID === selectedTank)
  };

  // console.log(filterData(statsData));
  const calculateTickCount = () => {
    if (selectedOption === "lastWeek") {
      return 7;
    } else if (selectedOption === "lastMonth") {
      return 10;
    } else {
      // selectedOption === "lastYear"
      return 12;
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-blue-900 text-white rounded-lg p-2">
          <p className="text-xs">{`Date: ${payload[0].payload.date}`}</p>
          <p className="text-xs">{`Value: ${payload[0].value.toFixed(2)}`}</p>
        </div>
      );
    }
    return null;
  };

  const getGraphData = (selectedTank) => {
    console.log(selectedTank);
    const arr = data.filter(item => item.deviceID === selectedTank);
    console.log(arr);
    return arr
  }

  return (
    <div>
      <div className=" grid grid-cols-8">
        <h2 className="text-xl col-span-6">Water Level</h2>
        <div className=" flex flex-row justify-between mr-9">
          <select
            value={selectedTank}
            onChange={handleChangeTank}
            className=" mr-8 mb-4 p-2 col-span-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {tanks.map((tank) => (
              <option value={tank.deviceID}>{tank.name}</option>

            ))}
            {/* <option value="lastMonth">Last Month</option>
            <option value="lastYear">Last Year</option> */}
          </select>
          <select
            value={selectedOption}
            onChange={handleOptionChange}
            className="mb-4 p-2 col-span-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="lastWeek">Last Week</option>
            <option value="lastMonth">Last Month</option>
            <option value="lastYear">Last Year</option>
          </select>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={200}>
        {!selectedTank ?

          <Dots size={60} />

          :
          <AreaChart data={filterData(data, selectedOption)}>
            <defs>
              <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#2451B7" stopOpacity={0.7} />
                <stop offset="75%" stopColor="#2451B7" stopOpacity={0.05} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="date"
              tickLine={false}
              tickCount={calculateTickCount()}
              tickFormatter={(str) => {
                const date = parseISO(str);
                return format(date, "MMM d");
              }}
              stroke="#D1D5DB"
            />
            <YAxis
              dataKey="value"
              axisLine={false}
              tickLine={false}
              tickCount={5}
              stroke="#D1D5DB"
            />
            <CartesianGrid opacity={0.5} vertical={false} strokeDasharray="3 3" />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ stroke: "red", strokeWidth: 2, strokeDasharray: "3 3" }}
            />
            <Area dataKey="value" stroke="#1E2772" fill="url(#color)" />
          </AreaChart>
        }

      </ResponsiveContainer>
    </div>
  );
};

export default AreaGraph;
