import React, { useEffect } from "react";

function StatsCard({ stats }) {
  useEffect(() => {
    console.log(stats.frequestTank);
  }, [stats])
  const stat = [
    { name: "Peak Water Level", stat: "2,400 ltrs" },
    { name: "Frequently used Tank", stat: "Tank 1" },
    { name: "Peak Location", stat: "Kabwata" },
  ];

  return (
    <div className="">
      {/* <h3 className="text-base font-semibold leading-6 text-gray-900">
        Last 30 days
      </h3> */}
      <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
        {stats?.frequestTank?.map((item) => (
          <div
            key={item.name}
            className="overflow-hidden rounded-lg bg-green-600 px-4 py-5 shadow-md sm:p-6"
          >
            <dt className="truncate text-sm font-medium text-white">
              {item.name}
            </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-white">
              {item.stat}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}

export default StatsCard;
