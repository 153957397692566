// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCLr6sDe4c57GCg-Uz4IkJqMiJulWtCLao",
  authDomain: "tandem-f135c.firebaseapp.com",
  projectId: "tandem-f135c",
  storageBucket: "tandem-f135c.appspot.com",
  messagingSenderId: "747377149670",
  appId: "1:747377149670:web:2b983bad3c604f8c94dd27",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const db = getFirestore(app)

const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };