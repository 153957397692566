import React, { useEffect, useState } from "react";
import SideNav from "../Components/SideNav";
import PerformanceTable from "../Components/PerformanceTable";
import { getDocs, collection, getFirestore } from "firebase/firestore";
import { db } from "../Firebase/firebaseConfig";

const Performance = () => {
  const [stats, setStats] = useState([]);
  const fetchData = async () => {
    try {
      // console.log(db);
      // const citiesRef = collection(db.app, 'stats');

      const statsSnapshot = await getDocs(collection(db, "stats"));
      const batStatusSnapshot = await getDocs(collection(db, "batStatus"));

      const statsData = statsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      const batStatusData = batStatusSnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data().bat;
        return acc;
      }, {});
      console.log(batStatusData);

      const combinedData = statsData.map(entry => {
        const { deviceID } = entry;
        // console.log(batStatusData[deviceID]);
        const batteryStatus = batStatusData[deviceID] || 0; // Default value if battery status not found
        return { ...entry, batteryStatus };
      });

      // Create an object to store the latest depth and timestamp for each device
      const latestDepthByDevice = {};

      combinedData.forEach(entry => {
        const { deviceID, createdAt, depth } = entry;

        // Check if deviceID already exists in latestDepthByDevice
        if (latestDepthByDevice[deviceID]) {
          // Compare createdAt timestamps to keep the latest depth and timestamp
          if (
            createdAt.seconds > latestDepthByDevice[deviceID].createdAt.seconds ||
            (createdAt.seconds === latestDepthByDevice[deviceID].createdAt.seconds &&
              createdAt.nanoseconds > latestDepthByDevice[deviceID].createdAt.nanoseconds)
          ) {
            latestDepthByDevice[deviceID] = { depth, createdAt };
          }
        } else {
          // If deviceID doesn't exist, add the entry
          latestDepthByDevice[deviceID] = { depth, createdAt };
        }
      });

      // Calculate the evaporation rate for each device
      const evaporationRates = [];

      Object.keys(latestDepthByDevice).forEach(deviceID => {
        const latestEntry = latestDepthByDevice[deviceID];
        const twoLatestEntries = combinedData.filter(entry => entry.deviceID === deviceID).slice(0, 2);

        if (twoLatestEntries.length === 2) {
          const timeDifferenceInSeconds = latestEntry.createdAt.seconds - twoLatestEntries[1].createdAt.seconds;
          const timeDifferenceInHours = timeDifferenceInSeconds / 3600;

          const depthDifference = latestEntry.depth - twoLatestEntries[1].depth;
          const evaporationRate = depthDifference / (timeDifferenceInHours == 0 ? 1 : timeDifferenceInHours);

          evaporationRates.push({ deviceID, evaporationRate });
        }
      });

      const uniqueDataArray = [];

      combinedData.forEach(entry => {
        const { deviceID, createdAt } = entry;

        // Check if deviceID already exists in uniqueDataArray
        const existingEntryIndex = uniqueDataArray.findIndex(item => item.deviceID === deviceID);

        if (existingEntryIndex !== -1) {
          // Compare createdAt timestamps to keep the latest data
          if (
            createdAt.seconds > uniqueDataArray[existingEntryIndex].createdAt.seconds ||
            (createdAt.seconds === uniqueDataArray[existingEntryIndex].createdAt.seconds &&
              createdAt.nanoseconds > uniqueDataArray[existingEntryIndex].createdAt.nanoseconds)
          ) {
            uniqueDataArray[existingEntryIndex] = entry;
          }
        } else {
          // If deviceID doesn't exist, add the entry
          uniqueDataArray.push(entry);
        }
      });

      const uniqueDataArrayWithRates = [];

      uniqueDataArray.forEach(entry => {
        const { deviceID } = entry;
        const evaporationEntry = evaporationRates.find(rateEntry => rateEntry.deviceID === deviceID);

        if (evaporationEntry) {
          uniqueDataArrayWithRates.push({
            ...entry,
            evaporationRate: evaporationEntry.evaporationRate,
            batteryStatus: entry.batteryStatus // Add battery status to the output
          });
        } else {
          uniqueDataArrayWithRates.push({
            ...entry,
            batteryStatus: entry.batteryStatus // Add battery status to the output
          });
        }
      });

      // Here you can set your state or do whatever you need with the data
      console.log(uniqueDataArrayWithRates);
      setStats(uniqueDataArrayWithRates)




      console.log('Eveporation:', uniqueDataArrayWithRates);
      // console.log(newData);

    } catch (error) {
      console.log('Found an Error: ', error);
    }
  }
  useEffect(() => {
    fetchData()

    // console.log('Received', uniqueDataArray);
    // console.log('Have', Tanks);
    // setStats(uniqueDataArray)
  }, [])
  return (
    <SideNav>
      <div className="text-3xl text-bold mb-4">Performance</div>
      <div className="bg-white rounded-md">
        <PerformanceTable statsData={stats} />
      </div>
    </SideNav>
  );
};

export default Performance;
