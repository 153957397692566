import React, { useEffect, useState } from "react";
import SideNav from "../Components/SideNav";
import StatsCard from "../Components/StatsCard";
import PerformanceTable from "../Components/PerformanceTable";
import AreaGraph from "../Components/AreaGraph";
import { getDocs, collection, getFirestore, getDoc, doc } from "firebase/firestore";
import { db } from "../Firebase/firebaseConfig";
// import { db } from "../../Firebase/firebaseConfig";
import { Dots } from "react-activity";

const Overview = () => {
  const [stats, setStats] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [frequentTank, setFrequentTank] = useState([]);
  const generateRandomValue = () => Math.floor(Math.random() * 2501); // Function to generate random value between 0 and 2500

  const fetchData = async () => {
    try {
      const statsSnapshot = await getDocs(collection(db, "stats"));
      const batStatusSnapshot = await getDocs(collection(db, "batStatus"));

      const statsData = statsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      const batStatusData = batStatusSnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data().bat;
        return acc;
      }, {});
      console.log(batStatusData);

      const combinedData = statsData.map(entry => {
        const { deviceID } = entry;
        // console.log(batStatusData[deviceID]);
        const batteryStatus = batStatusData[deviceID] || 0; // Default value if battery status not found
        return { ...entry, batteryStatus };
      });

      // Create an object to store the latest depth and timestamp for each device
      const latestDepthByDevice = {};

      combinedData.forEach(entry => {
        const { deviceID, createdAt, depth } = entry;

        // Check if deviceID already exists in latestDepthByDevice
        if (latestDepthByDevice[deviceID]) {
          // Compare createdAt timestamps to keep the latest depth and timestamp
          if (
            createdAt.seconds > latestDepthByDevice[deviceID].createdAt.seconds ||
            (createdAt.seconds === latestDepthByDevice[deviceID].createdAt.seconds &&
              createdAt.nanoseconds > latestDepthByDevice[deviceID].createdAt.nanoseconds)
          ) {
            latestDepthByDevice[deviceID] = { depth, createdAt };
          }
        } else {
          // If deviceID doesn't exist, add the entry
          latestDepthByDevice[deviceID] = { depth, createdAt };
        }
      });

      // Calculate the evaporation rate for each device
      const evaporationRates = [];

      Object.keys(latestDepthByDevice).forEach(deviceID => {
        const latestEntry = latestDepthByDevice[deviceID];
        const twoLatestEntries = combinedData.filter(entry => entry.deviceID === deviceID).slice(0, 2);

        if (twoLatestEntries.length === 2) {
          const timeDifferenceInSeconds = latestEntry.createdAt.seconds - twoLatestEntries[1].createdAt.seconds;
          const timeDifferenceInHours = timeDifferenceInSeconds / 3600;

          const depthDifference = latestEntry.depth - twoLatestEntries[1].depth;
          const evaporationRate = depthDifference / (timeDifferenceInHours == 0 ? 1 : timeDifferenceInHours);

          evaporationRates.push({ deviceID, evaporationRate });
        }
      });

      const uniqueDataArray = [];

      combinedData.forEach(entry => {
        const { deviceID, createdAt } = entry;

        // Check if deviceID already exists in uniqueDataArray
        const existingEntryIndex = uniqueDataArray.findIndex(item => item.deviceID === deviceID);

        if (existingEntryIndex !== -1) {
          // Compare createdAt timestamps to keep the latest data
          if (
            createdAt.seconds > uniqueDataArray[existingEntryIndex].createdAt.seconds ||
            (createdAt.seconds === uniqueDataArray[existingEntryIndex].createdAt.seconds &&
              createdAt.nanoseconds > uniqueDataArray[existingEntryIndex].createdAt.nanoseconds)
          ) {
            uniqueDataArray[existingEntryIndex] = entry;
          }
        } else {
          // If deviceID doesn't exist, add the entry
          uniqueDataArray.push(entry);
        }
      });

      const uniqueDataArrayWithRates = [];

      uniqueDataArray.forEach(entry => {
        const { deviceID } = entry;
        const evaporationEntry = evaporationRates.find(rateEntry => rateEntry.deviceID === deviceID);

        if (evaporationEntry) {
          uniqueDataArrayWithRates.push({
            ...entry,
            evaporationRate: evaporationEntry.evaporationRate,
            batteryStatus: entry.batteryStatus // Add battery status to the output
          });
        } else {
          uniqueDataArrayWithRates.push({
            ...entry,
            batteryStatus: entry.batteryStatus // Add battery status to the output
          });
        }
      });
      // Tank dimensions
      const width = 300; // cm
      const length = 200; // cm
      const offset = 29.33; // cm
      //const offset = 44; // cm

      // Iterate through the tank data array
      uniqueDataArrayWithRates.forEach(tank => {
        // Subtract the offset from the depth
        // const actualDepth = tank.depth - offset;
        const actualDepth = 42.4 - (tank.depth - offset);

        // Calculate the volume
        const volume = (actualDepth * width * length) / 1000;

        // Add the volume field to the object
        tank.volume = Number(volume.toFixed(0));
      });

      // Here you can set your state or do whatever you need with the data
      console.log(uniqueDataArrayWithRates);
      setStats(uniqueDataArrayWithRates)
      const extractData = (filteredData) => {
        return filteredData.map(item => {
          return {
            deviceID: item.deviceID,
            date: new Date(item.createdAt.seconds * 1000), // Convert seconds to milliseconds
            depth: item.depth,
            location: item.location,
            volume: item.volume
          };
        });
      };
      combinedData.forEach(tank => {
        // Subtract the offset from the depth
        //const actualDepth = tank.depth - offset;
        const actualDepth = 42.4 - (tank.depth - offset);

        // Calculate the volume
        const volume = (actualDepth * width * length) / 1000;

        // Add the volume field to the object
        tank.volume = Number(volume.toFixed(0));
      });
      console.log(combinedData);
      // Example usage:
      const convertedArray = extractData(combinedData);
      const convertDateFormat = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toISOString().split('T')[0];
        return formattedDate;
      };

      // Example usage:
      const dataArray = convertedArray.map(item => ({
        deviceID: item.deviceID,
        date: convertDateFormat(item.date),
        // value: item.depth,
        location: item.location,
        value: item.volume
      }));

      // console.log(extractedArray);
      const finalData = dataArray.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });

      const idCounts = {};
      finalData.forEach(item => {
        const id = item.deviceID;
        idCounts[id] = (idCounts[id] || 0) + 1;
      });

      // Find the most frequent deviceID
      let mostFrequentID;
      let maxCount = 0;

      for (const id in idCounts) {
        if (idCounts[id] > maxCount) {
          maxCount = idCounts[id];
          mostFrequentID = id;
        }
      }

      let highestValue = 0;
      dataArray.forEach(item => {
        if (item.value > highestValue) {
          highestValue = item.value;
        }
      });
      // Get a random location
      const randomIndex = Math.floor(Math.random() * dataArray.length);
      const randomLocation = dataArray[randomIndex].location;


      const docRef = doc(collection(db, 'devices'), mostFrequentID);
      const docSnap = await getDoc(docRef);
      const getName = () => {
        if (docSnap.exists()) {
          // Document exists, you can access its data using docSnap.data()
          const documentData = docSnap.data();
          console.log('Document data:', documentData);
          return documentData.name;
        } else {
          console.log('No such document!');
          return null;
        }

      }

      // const deviceSnapshot = await getDocs(collection(db, "devices"), mostFrequentID);
      // const deviceData = deviceSnapshot.docs.reduce((acc, doc) => {
      //   acc[doc.id] = doc.data().name;
      //   return acc;
      // }, {});
      // console.log(deviceData);



      // Create the stats array
      const stats = [
        { name: "Peak Water Level", stat: highestValue.toLocaleString() + " ltrs" },
        { name: "Frequently used Tank", stat: getName() || "None" }, // If no ID is found, set to "None"
        { name: "Peak Location", stat: randomLocation || "None" }, // If no location is found, set to "None"
      ];


      console.log(mostFrequentID);
      console.log(finalData);
      setFullData(finalData)
      setFrequentTank(stats)
    } catch (error) {
      console.log('Found an Error: ', error);
    }
  }



  useEffect(() => {
    fetchData()
  }, [])



  const generateDataForOneYear = () => {
    const data = [];
    const today = new Date();
    const oneYearAgo = new Date(
      today.getFullYear() - 1,
      today.getMonth(),
      today.getDate()
    );

    for (
      let date = oneYearAgo;
      date <= today;
      date.setDate(date.getDate() + 1)
    ) {
      const formattedDate = date.toISOString().substring(0, 10);
      const value = generateRandomValue();
      data.push({ date: formattedDate, value: value });
    }

    return data;
  };

  const data = generateDataForOneYear();
  console.log(data);

  return (
    <SideNav>
      <div className="min-h-screen grid grid-cols-8  ">
        <div className="col-span-1"></div>
        <div className="col-span-6">
          <div className="px-8">
            <StatsCard stats={{ frequestTank: frequentTank }} />
          </div>

          <div className="bg-white p-4 mt-10 rounded-lg shadow-lg">
            <AreaGraph data={fullData} statsData={stats} />
            {/* {stats ? 
            : 
            <Dots />
            } */}
          </div>

          <div className="bg-white mt-8 rounded-md mb-12 shadow-lg ">
            <PerformanceTable statsData={stats} />
          </div>
        </div>
        <div className="col-span-1"></div>
      </div>
    </SideNav>
  );
};

export default Overview;
