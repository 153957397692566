import React from "react";
import SideNav from "../Components/SideNav";
import SmartChip from "./SmartChip";

const Profile = () => {
  return (
    <SideNav>
      <div className='text-3xl text-bold mb-4'>Profile</div>
      <div>
        <SmartChip />
      </div>
    </SideNav>
  );
};

export default Profile;
