import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import logo from "../assets/Images/TandemLogo.png";
import logo from "../assets/Images/tandemLogo.png";
// import auth from "../../Firebase/firebaseConfig";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { LockClosedIcon, EnvelopeIcon, XMarkIcon } from "@heroicons/react/24/outline";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      alert("Please enter both email and password.");
      return;
    }

    try {
      setLoading(true);
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
      console.log("Login successful!", email);
      setLoading(false);
      navigate("/overview");
    } catch (error) {
      setLoading(false);
      if (error.code === "auth/invalid-credential") {
        // Clear input fields
        setEmail("");
        setPassword("");
        // Display error message overlay
        setError("Invalid email or password. Retry.");
        setTimeout(() => {
          setError(null);
        }, 5000);
      } else {
        alert(error.message);
      }
    }

  };

  const closeError = () => {
    setError(null);
  };

  return (
    <div className="flex min-h-screen grid grid-cols-3">
      {loading && (
        <div className="absolute inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      )}

      {error && ( // Conditionally render error overlay
        <div className="absolute inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-md shadow-lg">
            <button
              onClick={closeError}
              className="text-gray-400 hover:text-gray-600"
            >
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <p className="text-red-500">{error}</p>

          </div>
        </div>
      )}

      <div
        className="flex-1  bg-center bg-cover col-span-2"
        style={{ backgroundImage: `url('/LoginBG.jpeg')` }}
      ></div>

      <div className="flex-1 bg-white flex flex-col items-center justify-center px-4 sm:px-6">
        <div className="w-2/3 h-16">
          <img src={logo} alt="Login" />
        </div>
        <h2 className="mt-2 leading-9 font-semibold tracking-tight text-gray-700">
          Log in to your account
        </h2>

        <form className="w-full space-y-4" onSubmit={handleLogin}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm  leading-6 text-gray-900"
            >
              Email id:
            </label>
            <div className="flex">
              <input
                type="email"
                placeholder="noel@tandem.com"
                className="block bg-gray-200  w-full p-2 rounded-l-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-xl"
                value={email}
                name="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <div className="bg-blue-950 text-white size-10 rounded-r-md  shadow-sm flex-initial p-2">
                <EnvelopeIcon />
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm  leading-6 text-gray-950"
            >
              Password
            </label>
            <div className="flex">
              <input
                type="password"
                placeholder="Enter your password"
                className="block bg-gray-200 flex-1 p-2 w-full rounded-l-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-xl"
                value={password}
                name="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <div className="bg-blue-950 text-white size-10 rounded-r-md shadow-sm flex-initial p-2">
                <LockClosedIcon />
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <a
              href="/forgot-password"
              className=" text-blue-800 hover:text-blue-500 underline"
            >
              Forgot Password?
            </a>
          </div>
          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-blue-950 px-3 py-1.5 leading-6 text-white shadow-lg hover:bg-blue-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            Login Now
          </button>
        </form>

        {/* <div className=" w-full">
          <div className="grid grid-cols-7">
            <div className="border-t border-gray-300 my-4 w-full col-span-3"></div>
            <p className="text-gray-400 col-span-1 text-center ">OR</p>
            <div class="border-t border-gray-300 my-4 w-full col-span-3"></div>
          </div>
          <Link to="/sign-up">
            <button className="flex w-full outline justify-center rounded-md outline-blue-950 px-3 py-1.5 leading-6 text-blue-950 shadow-lg hover:bg-blue-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
              Sign Up Now
            </button>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Login;
