import React from "react";
import SideNav from "../Components/SideNav";
import SmartChipImage from "../assets/Images/smartChip.png";

const SmartChip = () => {
  return (
    <div className="p-4">
      <div className="bg-white rounded-md shadow-md w-4/5 p-8 text-left  ">
        <p>
          water level monitoring
          <br />
          <br />
          water level remote monitoring Our solution for detection of high water
          levels.
          <br />
          Immediate warning on rising levels
          <br />
          Sensors detect rising water levels.
          <br /> Eco-cube water level sensor can detect up to 3 different
          thresholds.
          <br /> Sustainable and resistant product
          <br />
          All R&D is conducted in-house. The hardware is of high quality, making
          use of top quality
        </p>
        <div>
          <img src={SmartChipImage} alt="smart chip" className="w-3/5" />
        </div>
      </div>
    </div>
  );
};

export default SmartChip;
