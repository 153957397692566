import logo from "../assets/Images/tandemLogo.png";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  Cog6ToothIcon,
  HomeIcon,
  UserIcon,
  XMarkIcon,
  ChartBarIcon
} from "@heroicons/react/24/outline";

import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { atom, useAtom } from "jotai";

const navigation = [
  { name: "Overview", href: "/overview", icon: HomeIcon, current: true },
  {
    name: "Performance", href: "/performance", icon: ChartBarIcon, current: false,
  },
  {
    name: "Profile",
    href: "/profile",
    icon: UserIcon,
    current: false,
  },
  {
    name: "Settings ",
    href: "/settings",
    icon: Cog6ToothIcon,
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const activeNavItemAtom = atom("/overview");

export default function SideNav({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeNavItem, setActiveNavItem] = useAtom(activeNavItemAtom);
  const location = useLocation();
  const history = useNavigate();

  useEffect(() => {
    const currentPath = location.pathname;
    setActiveNavItem(currentPath);
  }, [location.pathname, setActiveNavItem]);

  const handleNavItemClick = (href) => {
    setActiveNavItem(href);
    setSidebarOpen(false);
    localStorage.setItem("activeNavItem", href);
    history(href);
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 " onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>

                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src={logo}
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {navigation.map((item, i) => (
                              <li key={i}>
                                <NavLink
                                  to={item.href}
                                  onClick={() => handleNavItemClick(item.href)}
                                  className={classNames(
                                    item.current
                                      ? "bg-gray-50 text-blue-600"
                                      : "textom cookt-gray-700 hover:text-blue-600 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.href === activeNavItem
                                        ? "text-blue-600"
                                        : "text-gray-400 group-hover:text-blue-600",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </li>

                        <li className="mt-auto">
                          <Link to="/">
                            <button className="flex w-full justify-center rounded-md bg-blue-950 px-3 py-1.5 leading-6 text-white shadow-sm hover:bg-blue-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                              Logout
                            </button>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="bg-slate-100">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 p-4"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>

          {/* maincontent */}
          <main className="bg-slate-100 min-h-screen">
            <div className="px-8 ">{children}</div>
          </main>
        </div>
      </div>
    </>
  );
}
