import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { db } from "../Firebase/firebaseConfig";

const Tanks = [
  { Tank: "Tank 1", Location: "Kabwata", Size: "2500", FillLevel: "20%" },
  { Tank: "Tank 2", Location: "Kabwata", Size: "2500", FillLevel: "90%" },
  { Tank: "Tank 3", Location: "Kabwata", Size: "2500", FillLevel: "60%" },
];

export default function PerformanceTable(props) {
  const navigate = useNavigate()
  const [stats, setStats] = useState([]);
  const [tanks, setTanks] = useState([]);
  const [tankName, setTankName] = useState([]);


  useEffect(() => {
    console.log('Received', props.statsData);
    // console.log('Have', Tanks);
    getName();
    const mappedArray = props.statsData.map(item => {
      const name = tankName[item.deviceID];
      return { ...item, name };
    });

    // setTanks(mappedArray)

    console.log(mappedArray);
    setStats(mappedArray)
  }, [props.statsData])




  const getName = async () => {
    const deviceSnapshot = await getDocs(collection(db, "devices"));
    const deviceData = deviceSnapshot.docs.reduce((acc, doc) => {
      acc[doc.id] = doc.data().name;
      return acc;
    }, {});
    setTankName(deviceData)
    console.log(deviceData);
  }
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 mt-2 text-gray-900">
            Performance
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            onClick={() => navigate('/performance')}
            type="button"
            className="block rounded-2xl mt-2 bg-gray-200 px-3 py-2 text-center text-sm font-semibold text-blue-800 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            See All
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                  >
                    Tank ID
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                  >
                    Tank Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Location
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Size (Liters)
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Evaporation Rate
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Fill Level
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Volume
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Battery Level
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Last update
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {stats.map((tank) => (
                  <tr key={tank?.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                      {tank?.deviceID}
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                      {tank?.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      lat: {JSON.parse(tank?.location)?.latitude}, lon:{JSON.parse(tank?.location)?.longitude}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      2500 liters
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {Number(tank?.evaporationRate).toFixed(2)} m/h
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {tank?.depth}cm
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {tank?.volume} liters
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {tank?.batteryStatus} %
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {new Date(tank?.createdAt.seconds * 1000 + tank.createdAt.nanoseconds / 1e6).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
